<template>
    <section class="pa-4 formular">
        <v-container v-if="document_ready">
            <v-form @submit.prevent="submit" id="form" ref="form" class="formular price-list">
                <v-row :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4 pt-4">

                    <!--VALID-->
                    <v-col cols="6">
                        <v-dialog
                            ref="picker_validFrom"
                            v-model="picker_validFrom"
                            :return-value.sync="price_list.validFrom"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="price_list.validFrom"
                                    :label="$t('price_lists.table_keys.validFrom')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    color="gold"
                                />
                            </template>
                            <v-date-picker
                                v-model="validFrom"
                                scrollable
                                :first-day-of-week="1"
                                locale="sk"
                                color="gold"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="picker_validFrom = false"
                                >
                                    {{ $t('common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="$refs.picker_validFrom.save(validFrom);"
                                >
                                    {{ $t('common.ok') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="6">
                        <v-dialog
                            ref="picker_validTo"
                            v-model="picker_validTo"
                            :return-value.sync="price_list.validTo"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="price_list.validTo"
                                    :label="$t('price_lists.table_keys.validTo')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    color="gold"
                                />
                            </template>
                            <v-date-picker
                                v-model="validTo"
                                scrollable
                                :first-day-of-week="1"
                                locale="sk"
                                color="gold"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="picker_validTo = false"
                                >
                                    {{ $t('common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="$refs.picker_validTo.save(validTo);"
                                >
                                    {{ $t('common.ok') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" class="py-4">
                        <v-divider class="py-0"></v-divider>
                    </v-col>

                    <!--SAVING1G-->
                    <v-col cols="12" class="py-0">
                        <v-row justify="center" no-gutters>
                            <v-col cols="7" align="right">
                                <div class="pt-2 font-weight-medium gold--text">
                                    {{$t('price_lists.table_keys.saving1g_program')}}
                                </div>
                            </v-col>
                            <v-col cols="1" align="center">
                                <v-icon small class="mt-3">mdi-equal</v-icon>
                            </v-col>
                            <v-col cols="4">
                                <div class="body-1 white--text rounded mt-1 py-1" style="text-align: center;"
                                     :class="getPriceList.products[0].price !== 'NaN' ? 'green' : 'red'"
                                >
                                    <strong>{{ g_isDefined(g_parsePrice(this.price_list.saving1g)) }}</strong>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="py-4">
                        <v-divider class="py-0"></v-divider>
                    </v-col>

                    <!--PRODUCTS-->
                    <!--@keypress="onlyNumber($event)"-->
                    <v-col cols="12" class="py-0" v-for="(item,i) in getPriceList.products" :key="i">
                        <v-row class="" justify="center" no-gutters>
                            <v-col cols="4">
                                <v-text-field v-model="item.purchase"
                                              outlined dense color="gold" class=""
                                              :label="$t('price_lists.table_keys.' + item.product)"
                                              :placeholder="$t('price_lists.table_keys.' + item.product) +' '+ $t('price_lists.dialog.price')"

                                              @keyup="productPriceUpdate(i)"
                                              @focus="productPriceUpdate(i)"

                                              v-validate="'required|double'"
                                              :data-vv-name="'price_list_purchase' + i"
                                              :error-messages="errors.collect('price_list_purchase' + i)"
                                />
                            </v-col>
                            <v-col cols="1" align="center">
                                <v-icon small class="mt-3">mdi-plus</v-icon>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="item.margin"
                                              outlined dense color="gold" class="margin"
                                              :label="$t('price_lists.dialog.margin')"
                                              :placeholder="'%'"

                                              @keyup="productPriceUpdate(i)"
                                              @focus="productPriceUpdate(i)"

                                              v-validate="'required|double'"
                                              :data-vv-name="'price_list_margin_' + i"
                                              :error-messages="errors.collect('price_list_margin_' + i)"
                                />
                            </v-col>
                            <v-col cols="1" align="center">
                                <v-icon small class="mt-3">mdi-equal</v-icon>
                            </v-col>
                            <v-col cols="4" align="center">
                                <div class="body-1 white--text rounded mt-1 py-1" :class="item.price !== 'NaN' ? 'gold' : 'red'">
                                    <strong>{{ g_isDefined(g_parsePrice(item.price)) }}</strong>
                                </div>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
            </v-form>
            <div style="text-align: center;" class="pt-3">
                <v-btn dense depressed color="gold" dark @click="submit">{{ $t('common.save') }}</v-btn>
            </div>
        </v-container>

    </section>
</template>

<script>
import {createPriceList, updatePriceList} from "@/api/price_lists";

export default {
    name: "PriceListsDialog.vue",
    props: {
        edit: Boolean,
        type: String,
        id: undefined,
        card_item: undefined
    },
    data: () => ({
        picker_validFrom: false,
        picker_validTo: false,
        document_ready: false,
        price_list: {},
        validFrom: '',
        validTo: '',
        currency: "€"
    }),
    created() {
        this.$validator.extend('double', {
            validate: (value) => {
                return (/^[0-9]*[.]?[0-9]{0,2}$/g.test(value));
            }
        });

        if(this.edit) { // DETAIL EDIT
            let title = this.$t('price_lists.dialog.detail', {date: this.card_item.validFrom});
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});
            this.$root.$emit('overlay', true);
            this.$store.dispatch('getPriceList', this.card_item.id)
                .then(() => {
                    this.price_list = this.$store.getters.getPriceList;
                    this.document_ready = true;
                    this.$root.$emit('overlay', false);
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        } else { // CREATE
            this.$root.$emit('overlay', true);

            this.$store.dispatch('getCurrentPriceList')
                .then(() => {
                    this.$store.dispatch('getPriceList', this.$store.getters.getCurrentPriceList.id)
                        .then(() => {
                            this.price_list = this.$store.getters.getPriceList;
                            this.price_list.validFrom = this.g_getDate();

                            let today = new Date();
                            this.price_list.validTo = new Date(today.setDate(today.getDate() + 6) ).toISOString().slice(0,10) ;

                            this.document_ready = true;
                            this.$root.$emit('overlay', false);
                        })
                }).catch(()=>{
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.server_error'
                    });
                })
        }
    },
    computed: {
        getPriceList() {
            return this.price_list;
        },
    },
    methods: {
        onlyNumber: function(e) {
            e = (e) ? e : window.event;
            let expect = e.target.value.toString() + e.key.toString();

            if (!/^[0-9]*\.?[0-9]{0,2}$/g.test(expect)) {
                e.preventDefault();
            } else {
                return true;
            }
        },

        productPriceUpdate(_index) {
            let product = this.price_list.products[_index];
            product.price = parseFloat(parseFloat(product.purchase) * (parseFloat(product.margin) / 100 + 1)).toFixed(2);

            if(!_index) {
                this.price_list.saving1g = parseFloat(product.price * 0.9).toFixed(2);
            }
        },
        retypeProductPrices() {
            let retype = {};

            retype.saving1g = parseFloat(this.price_list.saving1g);

            for(let i=0; i<this.price_list.products.length; i++) {
                retype[this.price_list.products[i].product] = parseFloat(this.price_list.products[i].price);
                retype[this.price_list.products[i].product + 'Purchase'] = parseFloat(this.price_list.products[i].purchase);
                retype[this.price_list.products[i].product + 'Margin'] = parseFloat(this.price_list.products[i].margin);
            }

            return retype;
        },
        submit() {

            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if(!this.$validator.errors.any()) {
                    let price_list = {};

                    if(this.edit) price_list.id = this.card_item.id;
                    price_list.validFrom = this.price_list.validFrom;
                    price_list.validTo = this.price_list.validTo;

                    Object.assign(price_list, this.retypeProductPrices());

                    if(this.edit) {
                        updatePriceList(this.card_item.id, price_list).then(()=>{
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'info',
                                msg: 'notification.price_list_updated'
                            });
                            this.$parent.$emit('action', {func: 'updateItem', data: this.card_item.id});
                        }).catch(()=>{
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    } else {
                        createPriceList(price_list).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'info',
                                msg: 'notification.price_list_created'
                            });
                            this.$parent.$emit('call', {func: 'close', data: ''});
                        }).catch(()=>{
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: this.$validator.errors.items[0].rule === 'required' ? 'notification.form_error' : 'notification.form_error_correct'
                    });
                }
            });
        }
    },
    destroyed() {
        this.$store.dispatch('clearPriceList');
        this.$store.dispatch('clearCurrentPriceList');
    }
}
</script>

<style>
    .price-list .margin input {
        text-align: center;
    }
</style>